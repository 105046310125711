import { MovieSlideData } from '../../../domain/work';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { getMoviePosterUrl } from '../../../domain/utils/MovieUtils';
import classNames from 'clsx';
import { WithT } from 'i18next';

export interface MoviePosterProps extends WithT {
    movie: MovieSlideData;
    className?: string;
    // in pixels
    width?: number;
    height?: number;
    style?: CSSProperties;
    tooltipId?: string;
}

const defaultWidth = 160;
const defaultHeight = 213;
const ratioWidthByHeight: number = defaultWidth / defaultHeight;

function computeWidthAndHeight(
    width?: number,
    height?: number
): { width: number; height: number; explicitlyGivenDimensions: boolean } {
    let explicitlyGivenDimensions = true;
    if (isFinite(width) && !isFinite(height)) {
        height = width! / ratioWidthByHeight;
    }
    if (isFinite(height) && !isFinite(width)) {
        width = height! * ratioWidthByHeight;
    }
    if (!isFinite(height) && !isFinite(width)) {
        width = defaultWidth;
        height = defaultHeight;
        explicitlyGivenDimensions = false;
    }
    width = Math.round(width!);
    height = Math.round(height!);
    return { width, height, explicitlyGivenDimensions };
}

export const MoviePoster = ({
    movie,
    className,
    width: w,
    height: h,
    style,
    tooltipId,
    t,
}: MoviePosterProps): JSX.Element => {
    const ref = useRef<HTMLImageElement>(null);
    const [imageAlt, setImageAlt] = useState<string | undefined>();
    useEffect((): void => {
        if (
            ref.current !== null &&
            'loading' in HTMLImageElement.prototype &&
            (!ref.current.src ||
                ref.current.src.match('/static/img/transparent.png$') !== null ||
                ref.current.src !== ref.current.dataset.src) &&
            ref.current.dataset.src
        ) {
            ref.current.src = ref.current.dataset.src;
        }
    }, [ref, movie]);
    const { width, height, explicitlyGivenDimensions } = computeWidthAndHeight(w, h);
    const imgSrc = getMoviePosterUrl(width, height, movie) || '/static/img/movie-poster-placeholder.svg';

    useEffect((): void => {
        let imgAlt: string | undefined;
        setTimeout((): void => {
            imgAlt = movie && movie.poster && movie.poster.name;
            if (imgAlt == null || imgAlt.length < 1) {
                imgAlt = t('common:filmPoster');
            }
            setImageAlt(imgAlt);
        }, 10000);
    }, [movie, t]);

    if (explicitlyGivenDimensions) {
        style = { ...style, width: width, height: height };
    }
    style = {
        ...style,
        backgroundImage: `url('${getMoviePosterUrl(width, height, movie, true)}')`,
        backgroundSize: 'cover',
    };

    return (
        <img
            key={movie.id}
            ref={ref}
            data-tip={!isEmpty(tooltipId) ? true : undefined}
            data-for={!isEmpty(tooltipId) ? tooltipId : undefined}
            className={classNames('MoviePoster', 'lazyload', className)}
            width={width}
            height={height}
            data-src={imgSrc}
            src="/static/img/transparent.png"
            alt={imageAlt}
            style={style}
            loading="lazy"
        />
    );
};
