import {
    ReferenceItem,
    TrackedObject,
    Person,
    Organization,
    PersonData,
    OrganizationData,
    VocabularyEntry,
} from './core';
import { Asset } from './media';
import { Theme } from './editorial';
import { EmbeddedContract } from './legal';
import { VideoProduct } from './commerce';

export enum ContributionType {
    ACTOR,
    COMPOSER,
    DIRECTOR,
    DIRECTOR_OF_PHOTOGRAPHY,
    PRODUCER,
    WRITER,
    EXECUTIVE_PRODUCER = 'executive-producer',
}

export enum ColorType {
    COLORED = 'colored',
    BW = 'black-and-white',
    BW_AND_COLORED = 'black-and-white-and-colored',
    COLORIZED = 'colorized',
    RESTORED = 'restored',
}

export enum MovieStatus {
    ARCHIVED = 'archived',
    DRAFT = 'draft',
    EXPIRED = 'expired',
    PUBLICATION_READY = 'publication-ready',
    PUBLISHED = 'published',
}

export type Contribution = ReferenceItem & ContributionData & TrackedObject;

export interface ContributionData {
    contributor: ContributorData;
    movie: MovieData;
    type: ContributionType;
}

export type Contributor = ReferenceItem & ContributorData & TrackedObject;

export type PersonContributorData = { type: 'person'; country?: WorkCountry } & PersonData;

export type OrganizationContributorData = { type: 'organization'; country?: WorkCountry } & OrganizationData;

export type ContributorData = PersonContributorData | OrganizationContributorData;

export type Genre = ReferenceItem & GenreData & TrackedObject;

export interface GenreData extends VocabularyEntry {
    parent?: Genre;
}

export type Keyword = ReferenceItem & KeywordData & TrackedObject;

export interface KeywordData {
    name?: string;
    code?: string;
}

export type MovieSlideData = ReferenceItem &
    TrackedObject & {
        directors?: Contributor[];
        duration: number;
        genres?: Genre[];
        mainImage?: Asset;
        poster?: Asset;
        productionYear: number;
        synopsis: string;
        themes?: Theme[];
        title: string;
        workType?: string;
        releaseDate?: string;
        productionCountries: WorkCountry[];
    };

export type Movie = ReferenceItem & MovieData & TrackedObject;

export interface MovieData {
    actors?: Contributor[];
    awards?: string[];
    catalogCountries: WorkCountry[];
    catalogs?: MovieCatalog[];
    category?: MovieCategory;
    characters?: string[];
    colorType?: ColorType;
    composers?: Contributor[];
    credits?: WorkCreditsData[];
    directors?: Contributor[];
    directorsOfPhotography?: Contributor[];
    duration: number;
    durationType?: 'short-film' | 'feature-film';
    firstMovie?: boolean;
    restored?: boolean;
    genres?: Genre[];
    keywords?: Keyword[];
    mainColor?: chroma.Color; // FIXME : Probably not the type the endpoint will returned once fixed
    mainImage?: Asset;
    originalAudioLanguages?: WorkLanguage[];
    originalTitle?: string;
    poster?: Asset;
    producers?: Contributor[];
    productionCountries: WorkCountry[];
    productionYear: number;
    // resources?: AssetFile; // There is a dedicated endpoint for this...
    shootingCountries: WorkCountry[];
    status?: MovieStatus;
    subtitle?: string;
    synopsis: string;
    themes?: Theme[];
    title: string;
    trailer?: Asset;
    scenario?: Contributor[];
    vrInfo?: string;
    subtitleLanguages?: any;
    licenseAdditionalParam?: any;
    filmingFormat: string;
    _links?: MovieLinks;
    products?: VideoProduct[];
    contributions?: Contribution[];
    workType?: string;
    releaseDate?: string;
}

export interface MovieLinks {
    contract?: EmbeddedContract;
}

export type WorkCredits = ReferenceItem & WorkCreditsData;

export interface WorkCreditsData {
    credit: string;
    contributor: Person | Organization;
}

export type WorkCountry = ReferenceItem & TrackedObject & WorkCountryData;

export type WorkCountryData = VocabularyEntry;

export type WorkLanguage = ReferenceItem & TrackedObject & WorkLanguageData;

export type WorkLanguageData = VocabularyEntry;

export type MovieCatalog = ReferenceItem & TrackedObject & MovieCatalogData;

export type MovieCatalogData = VocabularyEntry;

export type MovieCategory = ReferenceItem & TrackedObject & MovieCategoryData;

export type MovieCategoryData = VocabularyEntry;

// must match definition in ifcinema/src/main/java/fr/ifcinema/work/model/MovieAssetCategory.java
// must defaults to media when not specified.
export enum AssetCategory {
    EDUCATIONAL = 'educational',
    MEDIA = 'media',
    TECHNICAL = 'technical',
    TECHNICAL_VIDEO = 'technical-video',
    TECHNICAL_TRAILER = 'technical-trailer',
}

export enum AssetType {
    EDUCATIONAL_FOLDER = 'educational-folder',
    DIALOG_LIST = 'dialog-list',
    POSTER = 'poster',
    TRAILER = 'trailer',
    PHOTO = 'photo',
    SUBTITLE = 'subtitle',
    VIDEO = 'video',
}

export interface MovieAsset {
    type: AssetType;
    asset: Asset;
    movie: Movie;
    category?: AssetCategory; // must defaults to media when not specified.
    pointOfInterest?: PointOfInterest;
}

export interface PointOfInterest {
    x: number;
    y: number;
}
