import { includes, map } from 'lodash';
import dayjs from 'dayjs';
import { GeoLocation } from '../../server/geoip-middleware';
import { LegalContract, LightLegalContractData } from '../legal';

const CANADIAN_COUNTRY_CODE = 'CAN';
const QUEBEC_REGION_CODE = 'QC';
const QUEBEC_COUNTRY_SUFFIX = 'Q';
const ENGLISH_CANADA_COUNTRY_SUFFIX = 'A';

export function isNotAvailableYet(legalContract?: LightLegalContractData): boolean {
    if (legalContract) {
        const contractStartingAt = legalContract.startingAt;
        return dayjs(contractStartingAt).isAfter(Date.now());
    }
    return false;
}

export function isNotFreelyAvailableYet(legalContract?: LightLegalContractData): boolean {
    if (legalContract) {
        const contractStartingAt = legalContract.freeStartingAt;
        return dayjs(contractStartingAt).isAfter(Date.now());
    }
    return false;
}

export function areNotAvailableYet(legalContracts?: LegalContract[]): boolean {
    if (legalContracts && legalContracts.length >= 1) {
        return isNotAvailableYet(legalContracts[0]);
    }
    return false;
}

export function isLastChance(legalContract?: LightLegalContractData): boolean {
    if (legalContract) {
        const diffInMonths: number = dayjs(legalContract.endingAt).diff(dayjs(), 'months', true);
        return !!legalContract.endingAt && 0 < diffInMonths && diffInMonths < 3;
    }
    return false;
}

export function isAvailableSoon(legalContract?: LightLegalContractData): boolean {
    if (legalContract) {
        const diffInMonths: number = dayjs(legalContract.startingAt).diff(dayjs(), 'months', true);
        return !!legalContract.startingAt && 0 < diffInMonths && diffInMonths < 3;
    }
    return false;
}

export function isFreelyAvailableSoon(legalContract?: LightLegalContractData): boolean {
    if (legalContract) {
        const diffInMonths: number = dayjs(legalContract.freeStartingAt).diff(dayjs(), 'months', true);
        return !!legalContract.freeStartingAt && 0 < diffInMonths && diffInMonths < 3;
    }
    return false;
}

export function hasExpiredRights(legalContracts?: LightLegalContractData): boolean {
    if (legalContracts) {
        return dayjs(legalContracts.endingAt).isBefore(Date.now());
    }
    return false;
}

export function hasExpiredFreeRights(legalContracts?: LightLegalContractData): boolean {
    if (legalContracts) {
        return dayjs(legalContracts.freeEndingAt).isBefore(Date.now());
    }
    return false;
}

export function hasContractExpiredRightsInLessThan30Days(legalContracts?: LightLegalContractData): boolean {
    if (legalContracts) {
        const diffInDays: number = dayjs(legalContracts.endingAt).diff(dayjs(), 'days', true);
        return !!legalContracts.endingAt && 0 < diffInDays && diffInDays < 30;
    }
    return false;
}

export function haveExpiredRights(legalContracts?: LegalContract[]): boolean {
    if (legalContracts && legalContracts.length >= 1) {
        return hasExpiredRights(legalContracts[0]);
    }
    return false;
}

export function isUnavailableInCountry(
    legalContracts: LegalContract[] | undefined,
    geoLocation: GeoLocation | undefined
): boolean {
    if (!geoLocation) {
        return true;
    }
    if (legalContracts && legalContracts.length >= 1) {
        const excludedCountries = map(legalContracts[0].excludedCountries, 'code');
        let countryCode = geoLocation.countryCode;
        if (CANADIAN_COUNTRY_CODE === countryCode && geoLocation.regionCode) {
            countryCode =
                CANADIAN_COUNTRY_CODE +
                (geoLocation.regionCode === QUEBEC_REGION_CODE ? QUEBEC_COUNTRY_SUFFIX : ENGLISH_CANADA_COUNTRY_SUFFIX);
        }
        return includes(excludedCountries, countryCode);
    }
    return false;
}
