import padStart from 'lodash/padStart';
import dayjs from 'dayjs';
import { Locale } from '../modules/locale';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';

export function setDayjsLocale(appLocale: Locale): void {
    dayjs.locale(appLocale);
}

/**
 * Given a running time in minutes, return a formatted duration like "5h03" or "28min"
 * @param durationInMinutes the duration in minutes to format
 */
export function formatDuration(durationInMinutes: number): string {
    let formattedDuration: string;
    if (durationInMinutes < 60) {
        formattedDuration = `${durationInMinutes}min`;
    } else {
        formattedDuration = `${Math.floor(durationInMinutes / 60)}h${padStart('' + (durationInMinutes % 60), 2, '0')}`;
    }
    return formattedDuration;
}

// TOFIX : This is not localized. But it's not used at a lot of places so maybe nobody will see it
export function fromNow(date) {
    const now = dayjs();
    const diffInMinutes = now.diff(dayjs(date), 'minute');

    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    const diffInHours = now.diff(dayjs(date), 'hour');
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    const diffInDays = now.diff(dayjs(date), 'day');
    if (diffInDays < 30) return `${diffInDays} days ago`;
    const diffInMonths = now.diff(dayjs(date), 'month');
    if (diffInMonths < 12) return `${diffInMonths} months ago`;
    const diffInYears = now.diff(dayjs(date), 'year');
    return `${diffInYears} years ago`;
}
