import {
    Movie,
    ContributorData,
    PersonContributorData,
    Contributor,
    MovieAsset,
    AssetCategory,
    AssetType,
    Contribution,
    MovieSlideData,
} from '../work';
import { LightLegalContractData } from '../legal';
import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import sample from 'lodash/sample';
import find from 'lodash/find';
import take from 'lodash/take';
import first from 'lodash/first';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { AssetFile } from '../media';
import { Genre } from '../work';
import { hasDefinedUrl } from './AssetFileUtils';
import chroma, { Color } from 'chroma-js';
import { PersonData } from '../core';
import { formatDuration } from '../../utils/DateAndTimeUtils';
import { MovieStateData } from '../../modules/movie';
import {
    isNotAvailableYet as isContractNotAvailableYet,
    isNotFreelyAvailableYet as isContractNotFreelyAvailableYet,
    hasExpiredRights as hasContractExpiredRights,
    hasExpiredFreeRights as hasContractExpiredFreeRights,
    isLastChance as isContractLastChance,
    isFreelyAvailableSoon as isContractFreelyAvailableSoon,
} from './LegalUtils';
import { FrontUser } from '../user';
import { includes, map } from 'lodash';
import { getLowDefOptimizedImageUrl, getOptimizedImageUrl } from '../../utils/imageOptimizer';

export function getMovieMainImageUrl(
    width: number,
    height: number,
    movie?: MovieSlideData,
    mode: 'random' | 'first' = 'random',
    lowDef?: boolean
): string | undefined {
    if (movie == null || movie.mainImage == null || movie.mainImage.files == null || movie.mainImage.files.length < 1) {
        return undefined;
    }
    const foundAssetFile: AssetFile | undefined =
        mode === 'first'
            ? find(movie.mainImage.files, hasDefinedUrl)
            : sample(filter(movie.mainImage.files, hasDefinedUrl));
    if (!foundAssetFile) {
        return undefined;
    }
    return lowDef
        ? getLowDefOptimizedImageUrl(foundAssetFile.url, width, height)
        : getOptimizedImageUrl(foundAssetFile.url, width, height);
}

export function getMovieTrailerUrlForPlayer(movie?: MovieStateData): string | undefined {
    const foundResource: MovieAsset | undefined = find(
        movie && movie.resources,
        (asset: MovieAsset): boolean => asset.category === AssetCategory.TECHNICAL_TRAILER
    );
    const foundAssetFile = first(foundResource && foundResource.asset.files);
    return foundAssetFile && foundAssetFile.url ? foundAssetFile.url : undefined;
}

export function getMoviePhotoResourcesUrls(movieAssets?: MovieAsset[]): string[] {
    if (movieAssets == null) {
        movieAssets = [];
    }

    return compact(
        movieAssets.map((movieAsset: MovieAsset): string | undefined => {
            let foundAssetFile: AssetFile | undefined;
            if (
                (movieAsset.category == null || movieAsset.category === AssetCategory.MEDIA) &&
                movieAsset.type === AssetType.PHOTO
            ) {
                foundAssetFile = find(movieAsset.asset.files, hasDefinedUrl);
            }
            return foundAssetFile ? foundAssetFile.url : undefined;
        })
    );
}

export function getFormattedMovieDuration(movie: MovieSlideData): string | undefined {
    let formattedDuration: string | undefined;
    if (!isFinite(movie.duration)) {
        return formattedDuration;
    }
    return formatDuration(movie.duration);
}

export function isPerson(contributorData: ContributorData): contributorData is PersonContributorData {
    return contributorData.type === 'person';
}

function nameLetterCase(name: string): string {
    return name
        .split(/\s*-\s*/)
        .map((part: string): string =>
            part
                .split(/\s+/)
                .map(upperFirst)
                .join(' ')
        )
        .join('-');
}

export function getPersonFullName(personData: PersonData): string {
    const firstName: string = personData.firstName ? nameLetterCase(personData.firstName) : '';
    const lastName: string = personData.lastName ? nameLetterCase(personData.lastName) : '';
    if (isEmpty(firstName)) {
        return lastName;
    }
    if (isEmpty(lastName)) {
        return firstName;
    }
    return `${firstName} ${lastName}`;
}

const mainCrewDpts = ['directors', 'actors', 'scenario', 'directorsOfPhotography', 'producers', 'composers'] as const;
type MainCrewDpt = (typeof mainCrewDpts)[number];
const isMainCrewDpt = (x: unknown): x is MainCrewDpt => mainCrewDpts.includes(x as MainCrewDpt);

export const contributorCrewDpts = [
    'executive-producer',
    'co-producer',
    'director-assistant',
    'distributor',
    'original-work-author',
    'composer',
    'song-writer',
    'editing',
    'animation',
    'writer',
    'cadre',
    'stunt-man',
    'dialog',
    'set-decoration',
    'costume',
    'special-effect',
    'comment',
] as const;
export type ContributorCrewDpt = (typeof contributorCrewDpts)[number];
type CrewDpt = MainCrewDpt | ContributorCrewDpt;

/**
 * Given a movie and a crew department name and an optional number of members to take from
 * the beginning of the list of crew department memberz, returns a properly formatted list
 * of these crew memberz or undefined if there is no member in your crew.
 * @param movie the movie
 * @param crewDpt the crew department name
 * @param nFirst The number of members to take from the beginning of the list of crew department memberz.
 *              Defaults to all if left undefined.
 */
export function getMovieDptCrewFullNamesList(movie: Movie, crewDpt: CrewDpt, nFirst?: number): string | undefined {
    let crew: Contributor[] | undefined = [];
    if (isMainCrewDpt(crewDpt)) {
        crew = movie[crewDpt];
    } else {
        crew = map(
            filter(movie.contributions, (datum): boolean => datum.type === crewDpt),
            (contribution: Contribution): Contributor => {
                return contribution.contributor as Contributor;
            }
        );
    }

    if (crew == null || crew.length < 1) {
        return undefined;
    }

    return take(crew, nFirst != null && nFirst > 0 ? nFirst : crew.length)
        .map((contributor: Contributor): string => {
            if (isPerson(contributor)) {
                return getPersonFullName(contributor);
            } else {
                return `${contributor.name}`;
            }
        })
        .join(', ');
}

export function getMovieGenres(movie?: MovieSlideData): string | undefined {
    if (movie == null || movie.genres == null || movie.genres.length < 1) {
        return undefined;
    }
    return compact(
        movie.genres.map((genre: Genre): string => {
            return genre.name;
        })
    ).join(', ');
}

export function getMoviePosterUrl(
    width: number,
    height: number,
    movie?: MovieSlideData,
    lowDef?: boolean
): string | undefined {
    return movie &&
        movie.poster &&
        movie.poster.files &&
        movie.poster.files.length > 0 &&
        movie.poster.files[0] &&
        movie.poster.files[0].url &&
        movie.poster.files[0].url.length > 10
        ? lowDef
            ? getLowDefOptimizedImageUrl(movie.poster!.files[0].url, width, height)
            : getOptimizedImageUrl(movie.poster!.files[0].url, width, height)
        : undefined;
}

function DEFAULT_MOVIE_MAIN_COLOR(): Color {
    return chroma('black');
}

export function getMovieColor(movie?: Movie): Color {
    return movie && movie.mainColor ? movie.mainColor : DEFAULT_MOVIE_MAIN_COLOR();
}

export function getContractFrom(movie: Movie): LightLegalContractData {
    return get(movie, '_links.contract.extra');
}

export function isFreelyWatchable(movie: Movie, checkDate?: boolean): boolean {
    const contract: LightLegalContractData = getContractFrom(movie);
    if (!contract || contract.freeEnabled === false) {
        return false;
    }

    if (checkDate) {
        const now = dayjs();
        if (contract.freeStartingAt && now.isBefore(contract.freeStartingAt)) {
            return false;
        }
        if (contract.freeEndingAt && now.isAfter(contract.freeEndingAt)) {
            return false;
        }
    }
    return true;
}

export function isNotAvailableYet(movie: Movie): boolean {
    return isContractNotAvailableYet(getContractFrom(movie));
}

export function isNotFreelyAvailableYet(movie: Movie): boolean {
    return isContractNotFreelyAvailableYet(getContractFrom(movie));
}

export function hasExpiredRights(movie: Movie): boolean {
    return hasContractExpiredRights(getContractFrom(movie));
}

export function hasExpiredFreeRights(movie: Movie): boolean {
    return hasContractExpiredFreeRights(getContractFrom(movie));
}

export function isLastChance(movie: Movie): boolean {
    return isContractLastChance(getContractFrom(movie));
}

export function isAvailableSoon(movie: Movie): boolean {
    return movie.releaseDate ? dayjs(movie.releaseDate).isAfter() : false;
}

export function isFreelyAvailableSoon(movie: Movie): boolean {
    return isContractFreelyAvailableSoon(getContractFrom(movie));
}

export function isUnavailableInUserCountry(movie: MovieStateData, user: FrontUser): boolean {
    if (user.geolocationUnlocked !== true && movie.legalContracts && movie.legalContracts.length >= 1) {
        const excludedCountries = map(movie.legalContracts[0].excludedCountries, 'code');
        return includes(excludedCountries, get(user, 'centre.country.code'));
    }
    return false;
}
