export function getOptimizedImageUrl(imageUrl: string, width: number, height?: number): string {
    let newImageUrl = imageUrl;
    if (imageUrl.startsWith('https://ifcinemastorage.lab.arte.tv')) {
        newImageUrl = imageUrl.replace('https://ifcinemastorage.lab.arte.tv', 'https://medias-ifcinema.lab.arte.tv/');
        newImageUrl = newImageUrl + `?width${width}`;

        if (height) {
            newImageUrl = newImageUrl + `&height=${height}`;
        }
    }

    return newImageUrl;
}

export function getLowDefOptimizedImageUrl(imageUrl: string, width: number, height: number): string {
    let newImageUrl = imageUrl;
    if (imageUrl.startsWith('https://ifcinemastorage.lab.arte.tv')) {
        newImageUrl = imageUrl.replace('https://ifcinemastorage.lab.arte.tv', 'https://medias-ifcinema.lab.arte.tv/');
        newImageUrl = newImageUrl + `?width${Math.floor(width / 3)}&height=${Math.floor(height / 3)}&blur=25`;
    }

    return newImageUrl;
}
